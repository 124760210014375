import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import PageWrapper from "../components/shared/PageWrapper"

const LoginPage = () => (
  <Layout>
    <Seo title="Contact" />
    <PageWrapper prose>
      <h2 className="text-2xl text-indigo-600">Contact</h2>
      <p className="mb-5">
        If you are interested in registering for lessons, please complete the
        form below.
      </p>
      <iframe
        id="sb_registration"
        src="https://app.mymusicstaff.com/Widget/v3/content/en/index.html?id=sch_KgXJD&accentColor=6366f1&accentTextColor=FFFFFF"
        className="sb_registration"
        allowtransparency="true"
        frameborder="0"
        style={{
          width: "100%",
          overflow: "hidden",
          maxWidth: "600px",
        }}
        height="800"
      ></iframe>
    </PageWrapper>
  </Layout>
)

export default LoginPage
